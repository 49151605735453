import React from 'react';
import image from "../Photos/Hiring.jpg"

const HiringModel = () => {

  return (
    <div className="flex flex-col items-center justify-center w-full h-full ">
      <div className="">
        <img src={image} alt="Hiring Image" className="" />
      </div>

      <div className="text-center p-5">
        <p className='text-2xl font-extrabold ' >We Are Hiring!</p>
        <p className='text-md font-semibold ' >Join Our Team.</p>
        <button className="rounded-full text-md text-center px-8 py-1 bg-blue-600 text-white hover:bg-blue-900 m-3 "><a href="https://bit.ly/48RIvJa" rel="noreferrer" target="_blank" >Apply Now</a></button>
      </div>
    </div>
  );
};

export default HiringModel;
