import React, {useEffect} from 'react';

import 'aos/dist/aos.css'; 
import AOS from 'aos';

const Internship_Card = ({ title, description, imageUrl }) => {
    useEffect(() => {
        AOS.init();
      }, []);

    return (
        <div data-aos="flip-up" data-aos-delay="200" data-aos-duration="1000" className=" w-96 m-3 my-10 bg-white rounded-md overflow-hidden shadow-lg border hover:scale-105 hover:bg-gray-100">
            <img
                src={imageUrl}
                alt={`Internship Image for ${title}`}
                className="w-60 h-60 object-cover p-5 m-auto hover:scale-125 "
            />

            <div className="p-4 text-center ">
                <h2 className="text-2xl font-bold mb-2">{title}</h2>
                <p className="text-gray-700 mb-2">{description}</p>
                <div className='flex items-center justify-center'>
                    <button
                        className="rounded-full text-lg text-center px-8 py-1 bg-blue-600 text-white hover:bg-blue-900 m-3 "
                    >
                        <a href="https://bit.ly/48RIvJa" rel="noreferrer" target="_blank">Apply Now</a>
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Internship_Card;

