import React, {useEffect} from 'react'
import Internship_Card from './Internship_Card'
import image1 from "../Photos/Web_Development.png"
import image2 from "../Photos/Full_Stack_Development.svg" 
import image3 from "../Photos/Android_Development.png" 
import image4 from "../Photos/C_C++_Programming.png" 
import image5 from "../Photos/Java_Programming.svg" 
import image6 from "../Photos/Python_Programming.svg" 
import image7 from "../Photos/UI_UX_Designing.jpg" 
import image8 from "../Photos/Machine_Learning.jpg"
import image9 from "../Photos/Artificial_Intelligence.jpg"  

import 'aos/dist/aos.css'; 
import AOS from 'aos';

export default function Internships() {
    useEffect(() => {
        AOS.init();
      }, []);

    return (
        <div data-aos="zoom-in" data-aos-delay="200" data-aos-duration="1000" id="internships" className='px-3 py-20 bg-gray-100 '>
            <h1 className='text-4xl lg:text-5xl md:text-5xl sm:text-4xl font-bold text-center pb-5' >Our Internships</h1>
            <div className='flex flex-wrap justify-evenly '>
                <Internship_Card
                    title="Web Development (Basic)"
                    description="Embark on your web development journey with our Basis Web Development Internship. Learn foundational skills in HTML, CSS, and JavaScript, and gain hands-on experience building responsive and user-friendly websites."
                    imageUrl={image1}
                />
                <Internship_Card
                    title="Full Stack Development"
                    description="Explore both front-end and back-end technologies with our Full Stack Development Internship. Develop a understanding of web development, utilizing frameworks like React and Node.js to create end-to-end solutions."
                    imageUrl={image2}
                />
                <Internship_Card
                    title="Android Development "
                    description="Explore the world of mobile app development with our Android Development Internship. Learn to build innovative and user-friendly Android applications using Java and Kotlin, and contribute to the growing field of mobile technology."
                    imageUrl={image3}
                />
                <Internship_Card
                    title="C/C++ Programming"
                    description="Master the fundamentals of programming with our C/C++ Programming Internship. Develop strong coding skills, work on algorithmic problem-solving, and contribute to software development using these powerful languages."
                    imageUrl={image4}
                />
                <Internship_Card
                    title="Java Programming"
                    description="Dive into the world of Java with our Java Programming Internship. Learn object-oriented programming principles, work on Java-based applications, and gain practical experience in building robust and scalable software."
                    imageUrl={image5}
                />
                <Internship_Card
                    title="Python Programming"
                    description="Unlock the potential of Python with our Python Programming Internship. Explore the versatility of this language in web development, data science, and automation, and contribute to cutting-edge projects using Python."
                    imageUrl={image6}
                />
                <Internship_Card
                    title="UI/UX Designing"
                    description="Craft visually appealing and user-centric designs with our UI/UX Designing Internship. Master the principles of user interface and user experience design, and actively contribute to creating engaging and intuitive digital experiences."
                    imageUrl={image7}
                />
                <Internship_Card
                    title="Machine Learning"
                    description="Delve into the exciting field of Machine Learning with our internship program. Work with data, algorithms, and models to gain insights, make predictions, and contribute to advancements in artificial intelligence."
                    imageUrl={image8}
                />
                <Internship_Card
                    title="Artificial Intelligence"
                    description="Explore the future of technology with our Artificial Intelligence Internship. Immerse yourself in the realms of machine learning, natural language processing, and computer vision, contributing to cutting-edge AI projects."
                    imageUrl={image9}
                />
            </div>
        </div>
    )
}
