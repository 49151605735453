import React, { useEffect } from 'react';

import 'aos/dist/aos.css';
import AOS from 'aos';

export default function Home() {

    useEffect(() => {
        AOS.init();
      }, []);

    return (
        <div data-aos="zoom-in" data-aos-delay="200" data-aos-duration="1000" >
            <div id="home" className='flex flex-col justify-center items-center min-h-screen' >
                <div>
                    <div>
                        <h1 className={`text-4xl lg:text-6xl md:text-6xl sm:text-5xl font-bold text-center text-blue-600 animate-fade-in`}>Shape Your Future Code</h1>
                        <h2 className={`text-xl lg:text-3xl md:text-2xl sm:text-xl font-semibold text-center p-4 animate-fade-in `} >Your Vision, Our Team – Building IT Excellence Together.</h2>
                    </div>
                    <div className='flex items-center justify-center ' >
                        <button className='rounded-full font-medium text-xl text-center px-3 py-2 bg-blue-700 text-white hover:bg-blue-500 m-3' ><a href="/#internships">Get Started</a></button>
                    </div>
                </div>
                <div>
                </div>
            </div>
        </div>
    )
}
