import React,{useEffect} from 'react'
import image from "../Photos/About.jpg"

import 'aos/dist/aos.css'; 
import AOS from 'aos';

export default function About() {

    useEffect(() => {
        AOS.init();
      }, []);

    return (
        <div id="about" data-aos="zoom-in" data-aos-delay="200" data-aos-duration="1000" className="flex items-center justify-center bg-gray-100 px-3 py-20" > 
            <div className="container mx-auto  ">
                <h1 className='text-4xl lg:text-5xl md:text-5xl sm:text-4xl font-bold text-center pb-16' >About Us</h1>
                <div className="md:hidden">
                    <img
                        src={image}
                        alt="About Us Image"
                        className="rounded-lg shadow-md mx-auto mb-6 hover:scale-50"
                    />
                </div>
                <div className='lg:flex' >
                    <div className="lg:w-1/2 lg:mr-8 mb-6 hidden md:block m-auto">
                        <img
                            src={image}
                            alt="About Us Image"
                            className="rounded-lg shadow-md"
                        />
                    </div>

                    <div className="lg:w-1/2 text-md ">
                        <p>
                            <div className='font-semibold text-3xl text-blue-700 font-serif ' >
                                Edu Intern: Shape Your Future Code
                            </div>

                            <div className='py-2'>
                                <span className='font-semibold ' >Mission:</span>
                                Edu Intern guides aspiring technologists towards successful careers through a blend of hands-on internships and comprehensive learning experiences. We aim to shape proficient coders and innovative minds.
                            </div>

                            <div className='py-2'>
                                <span className='font-semibold ' > Vision:</span>
                                We envision a world where everyone, regardless of background, has access to resources for transforming tech aspirations into achievements. Edu Intern strives to create a community of tech enthusiasts contributing meaningfully to the broader tech ecosystem.
                            </div>

                            <div className='py-2'>
                                <span className='font-semibold ' >Objectives:</span>
                                <div>
                                    <span className='font-semibold ' >1. Comprehensive Learning:</span>
                                    Edu Intern provides diverse, industry-relevant education, ensuring a holistic understanding of technology.
                                </div>
                                <div>
                                    <span className='font-semibold ' >2. Hands-On Internships:</span>
                                    Practical exposure is crucial. Edu Intern offers hands-on opportunities, allowing participants to apply theory in real-world scenarios.
                                </div>
                                <div>
                                    <span className='font-semibold ' >3. Guidance Toward Excellence:</span>
                                    Beyond technical skills, Edu Intern fosters creativity, resilience, and a passion for lifelong learning.
                                </div>
                                <div>
                                    <span className='font-semibold ' >4. Industry Alignment:</span>
                                    Programs are aligned with industry demands, enhancing participants' employability with the latest skills.
                                </div>
                            </div>

                            <div className='py-2' >
                                <span className='font-semibold '>Your Vision, Our Team:</span> Building IT Excellence Together
                            </div>

                            <div className='py-2' >
                                Edu Intern collaborates with you to lay the foundation for future tech endeavors. Join us on this journey where your vision meets our expertise, shaping the future of technology with passion and purpose.
                            </div>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}
