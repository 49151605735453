import React from 'react';

const Footer = () => {
  return (
    <div id="footer" >
      <div className="bg-white text-black pt-12">
        <div className="container mx-auto flex flex-wrap justify-around font-semibold p-5">

          <div className="w-full md:w-1/4 lg:w-1/5 mb-8">
            <h3 className="text-lg font-semibold mb-4">About Us</h3>
            <p className="text-sm">Edu Intern: Transforming education through innovative internships. Connecting talent with opportunities to shape digital futures.</p>
          </div>

          <div className="w-full md:w-1/4 lg:w-1/5 mb-8 ">
            <h3 className="text-lg font-semibold mb-4">Quick Links</h3>
            <a href="/policy#Terms&Conditions" className="block mb-2">Terms & Conditions</a>
            <a href="/policy#PrivacyPolicy" className="block mb-2">Privacy Policy</a>
            <a href="/policy#RefundPolicy" className="block mb-2">Refund Policy</a>
            <a href="/policy#Copyrights" className="block mb-2">Copyrights</a>
          </div>

          <div className="w-full md:w-1/4 lg:w-1/5 mb-8">
            <h3 className="text-lg font-semibold mb-4">Follow Us</h3>
            <a href="https://www.linkedin.com/company/101106182/admin/feed/posts/" className="block mb-2" target='_blank' rel="noreferrer">
              <i className="fab fa-linkedin"></i> LinkedIn
            </a>
            <a href="https://chat.whatsapp.com/G4JOdgxqmJeIJM7aCajaOv" className="block mb-2" target='_blank' rel="noreferrer">
              <i className="fab fa-whatsapp"></i> Whatsapp
            </a>
            <a href="#footer" className="block mb-2" rel="noreferrer"> 
              <i className="fab fa-instagram"></i> Instagram
            </a>
            <a href="#footer" className="block mb-2" rel="noreferrer">
              <i className="fab fa-facebook"></i> Facebook
            </a>
          </div>

          <div className="w-full md:w-1/4 lg:w-1/5 mb-8">
            <h3 className="text-lg font-semibold mb-4">Contact Info</h3>
            {/* <div className="info mb-2 flex">
            <div>
              <i className="fas fa-2x fa-phone my-auto p-2"></i>
            </div>
            <div className='px-5'>
            <p>8800179011</p>
            <p>9283642389</p>
            </div>
          </div> */}
            <div className="info mb-2 flex">
              <i className="fas fa-2x fa-envelope my-auto p-2"></i>
              <p>
                contact@eduintern.live
                <br />
                <a href="mailto:services.eduintern@gmail.com">internships.eduintern@gmail.com</a>
              </p>
            </div>
            <div className="info mb-2 flex">
              <i className="fas fa-2x fa-map-marker-alt p-2"></i>
              <p>RMZ Ecospace, Bengaluru, Karnataka, India - 560001</p>
            </div>
          </div>
        </div>
        <hr className="border-t border-black" />

        <h1 className="text-center  text-md my-2 font-extrabold py-2">
          © Copyright 2024 Edu Intern. All Rights Reserved.
        </h1>
      </div>
    </div>
  );
};

export default Footer;
