import React from "react";
import { Collapse } from "react-collapse";
import { AiOutlineMinus, AiOutlinePlus } from "react-icons/ai";
import { FaArrowCircleDown , FaArrowCircleUp } from "react-icons/fa";
import "./FAQAccordion.css"

const FaqData = ({ open, toggle, question, answer }) => {
  return (
    <div className="pt-2">
      <div
        className="bg-[#2563eb] py-3 px-4 flex justify-between items-center cursor-pointer"
        onClick={toggle}
      >
        <p className="text-lg font-semibold text-white">{question}</p>
        <div className="text-2xl updown " >
          {open ? <FaArrowCircleDown /> : <FaArrowCircleUp />}
        </div>
      </div>

      <Collapse isOpened={open}>
        <div className="bg-white px-4 pb-3 text-base">{answer}</div>
      </Collapse>
    </div>
  );
};

export default FaqData;
