import React,{useEffect} from 'react';
import Service_Card from './Service_Card';
import image1 from "../Photos/Full_Stack_Development.svg"
import image2 from "../Photos/Android_Development_Solutions.svg"
import image3 from "../Photos/Graphic_Designer.svg"
import image4 from "../Photos/UIUX_Designing_Solutions.jpg"
import image5 from "../Photos/Database_Management.jpg"
import image6 from "../Photos/Machine_Learning_Solutions.svg"

import 'aos/dist/aos.css'; 
import AOS from 'aos';

const Services = () => {

  useEffect(() => {
    AOS.init();
  }, []);

  const servicesData = [
    {
      title: 'Web Development',
      description: 'Crafting modern and responsive websites tailored to your unique needs. Our Web Development service ensures a seamless online presence, blending cutting-edge technologies with intuitive user experiences.',
      imageSrc: image1,
    },
    {
      title: 'Android Development',
      description: 'Building innovative and user-friendly Android applications. Our Android Development service focuses on delivering feature-rich mobile experiences, pushing the boundaries of functionality and usability.',
      imageSrc: image2,
    },
    {
      title: 'Database Management',
      description: "Delivering cutting-edge artificial intelligence solutions for various domains. Our AI Solutions service empowers businesses with innovative and tailored solutions, driving efficiency and fostering growth.",
      imageSrc: image5,
    },
    {
      title: 'Graphic Designing',
      description: 'Creating visually stunning designs that leave a lasting impression. Our Graphic Designing service transforms ideas into captivating visuals, combining creativity with strategic thinking for impactful brand communication.',
      imageSrc: image3,
    },
    {
      title: 'UI/UX Design',
      description: 'Crafting intuitive and visually appealing user interfaces and experiences. Our UI/UX Design service prioritizes user satisfaction, seamlessly blending aesthetics with functionality for an engaging digital journey.',
      imageSrc: image4,
    },
    {
      title: 'Machine Learning Solutions',
      description: "Exploring data, algorithms, and models to make meaningful predictions. Our Machine Learning service harnesses the power of data science, unlocking insights and paving the way for intelligent and informed decision-making.",
      imageSrc: image6,
    },
  ];

  return (
    <div data-aos="zoom-in" data-aos-delay="200" data-aos-duration="1000" id="services" className='bg-white px-3 py-20' >
      <h1 className='text-4xl lg:text-5xl md:text-5xl sm:text-4xl font-bold text-center pb-5' >Our Services</h1>
      <div className=" flex flex-wrap justify-evenly  ">
        {servicesData.map((service, index) => (
          <Service_Card key={index} {...service} />
        ))}
      </div>
    </div>
  );
};

export default Services;
