import React, { useEffect } from 'react';
import Navbar from './Components/Navbar/Navbar';
import HiringModel from './Components/Model/HiringModel';
import Footer from './Components/Footer/Footer';
import Guidence from "./Components/Guidance/Guidance"

import { BrowserRouter, Routes, Route } from 'react-router-dom';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Code from './Components/Code';
import Terms from './Components/TermsCondtion/Terms';

function App() {
  
  useEffect(() => {
      toast(<HiringModel />, { autoClose: false });
  }, []);

  return (
    <div>
      {/* <ScrollProgressBar /> */}
      <BrowserRouter>
        <Navbar />
        <Routes>
          <Route path="*" element={<Code />} />
          <Route path="guidence*" element={<Guidence />} />
          <Route path="policy*" element={<Terms/>} />
        </Routes>
        <Footer />
        <ToastContainer style={{ top: "50%", left: "50%", transform: "translate(-50%, -50%)" }} />
      </BrowserRouter>
    </div>
  );
}

export default App;
