import React, { useState, useEffect } from 'react'
import FaqData from "./FaqData";
import image from "../Photos/FAQ.svg"

import 'aos/dist/aos.css'; 
import AOS from 'aos';


export default function Faq() {

    useEffect(() => {
        AOS.init();
    }, []);

  const [open, setOpen] = useState(false);

  const toggle = (index) => {
    if (open === index) {
      return setOpen(null);
    }

    setOpen(index);
  };
  const faqData = [
    {
      question: "How long is the internship going to endure?",
      answer: "The internship will run for one month..",
    },
    {
      question: "How can I apply to be an intern?",
      answer:
        "Complete the application form associated with the domain you have in mind. Submit in the filled-out application. After reviewing your application, an evaluation of your eligibility for the internship will be conducted. You will be given a selection certificate along with a work list if you satisfy the requirements.",
    },
    {
      question:
        "I performed all of my assignments ahead of schedule. What time will my certificate arrive?",
      answer:
        "Make sure you turn in all of your work using the specific task submission form that was sent to you the last week of your internship if you want to get your completion certificate. You should expect to get your completion certificate in the third week of the month after you have successfully turned in your assignments.",
    },
    {
      question: "What are the requirements to be eligible for a certificate?",
      answer:
        "You must create an appealing LinkedIn profile, finish at least two projects related to your field of expertise, and turn in assignments on time in order to receive an internship completion certificate.",
    },
    {
      question:
        "How should I approach about obtaining a Letter of Recommendation (LOR)?",
      answer:
        "In order to receive a letter of recommendation, or LOR, you must finish all projects within your domain and participate in peer evaluation, which involves going over at least three assignments that other interns have placed on LinkedIn.",
    },
    {
      question: "Can I modify the domain of my internship after applying?",
      answer:
        "No, you are unable to alter your application during the internship term once you have submitted it for a certain domain.",
    },
    {
      question: "Does the internship need any prior experience?",
      answer:
        "Although it's not required, it might be helpful to have relevant experience or abilities in the field of your choice.",
    },
  ];

  return (
    <div data-aos="zoom-in" data-aos-delay="200" data-aos-duration="1000" id="faqs" className='py-20 pb-10 bg-gray-100'>
      <h1 className='text-4xl lg:text-5xl md:text-5xl sm:text-4xl font-bold text-center ' >FAQ</h1>
      <div className="flex items-center justify-center  px-3">
        <div className="container mx-auto  ">
          <div className="md:hidden">
            <img
              src={image}
              alt="About Us Image"
              className="rounded-lg shadow-md mx-auto mb-6 hover:scale-50 "
            />
          </div>
          <div className='lg:flex' >
            <div className="lg:w-1/2 mb-6 hidden md:block ">
              <img
                src={image}
                alt="About Us Image"
                className="rounded-lg"
              />
            </div>

            <div className="lg:w-1/2 text-md my-auto px-5">
              {faqData.map((data, index) => {
                return (
                  <FaqData 
                    key={index}
                    open={index === open}
                    question={data.question}
                    answer={data.answer}
                    toggle={() => toggle(index)}
                  />
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

