import React, { useState, useEffect } from 'react'
import image from "../Photos/EduIntern_Logo_2.jpg"
import image1 from "../Photos/Toggle.png"

export default function Navbar() {

    const [isMobile, setIsMobile] = useState(false);
    const [showNavbar, setShowNavbar] = useState(false);

    const handleResize = () => {
        setIsMobile(window.innerWidth < 883);
    };

    const handleToggle = () => {
        setShowNavbar(!showNavbar);
    };

    useEffect(() => {
        handleResize();
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div className='w-full fixed z-20 bg-white'>
            <div className='flex'>
                <div className='p-3 pl-5 xl:pr-96 lg:pr-36 md:pr-1 flex ' >
                    <img className='max-h-12 max-w-12 rounded-3xl ' src={image} alt="" />
                    <h1 className='text-black text-2xl xl:text-3xl lg:text-3xl md:text-3xl sm:text-2xl font-serif font-bold pl-2 py-2' >Edu Intern</h1>
                </div>
                <nav >
                    {isMobile ? (
                        <button className="text-black absolute right-0 " onClick={handleToggle}>
                            <img className='p-4 pt-2 h-18 w-20 ' src={image1} alt="" />
                        </button>
                    ) : (

                        <ul className='flex font-extrabold '>
                            <li className='p-2 mx-2 my-4 text-black text-md font-sans font-bold rounded-lg hover:bg-blue-700 hover:text-white '><a href="/#home">Home</a></li>
                            <li className='p-2 mx-2 my-4 text-blue-700 text-md font-sans font-bold rounded-lg hover:bg-blue-700 hover:text-white '><a href="/#about">About</a></li>
                            <li className='p-2 mx-2 my-4 text-blue-700 text-md font-sans font-bold rounded-lg hover:bg-blue-700 hover:text-white '><a href="/#services">Services</a></li>
                            <li className='p-2 mx-2 my-4 text-blue-700 text-md font-sans font-bold rounded-lg hover:bg-blue-700 hover:text-white '><a href="/#internships" >Internships</a></li>
                            <li className='p-2 mx-2 my-4 text-blue-700 text-md font-sans font-bold rounded-lg hover:bg-blue-700 hover:text-white '><a href="/#contact">Contact </a></li>
                            <li className='p-2 mx-2 my-4 text-blue-700 text-md font-sans font-bold rounded-lg hover:bg-blue-700 hover:text-white '><a href="/#faqs">FAQ's</a></li>
                            <li className='p-2 mx-2 my-4 text-blue-700 text-md font-sans font-bold rounded-lg hover:bg-blue-700 hover:text-white '><a href="/guidence">Guidence</a></li>
                        </ul>
                    )}

                    {isMobile && showNavbar && (

                        <ul className='font-extrabold absolute right-1 bg-white mt-20'>
                        <li className='p-2 mx-2 my-4 text-black text-md font-sans font-bold rounded-lg hover:bg-blue-700 hover:text-white '><a href="/#home">Home</a></li>
                            <li className='p-2 mx-2 my-4 text-blue-700 text-md font-sans font-bold rounded-lg hover:bg-blue-700 hover:text-white '><a href="/#about">About</a></li>
                            <li className='p-2 mx-2 my-4 text-blue-700 text-md font-sans font-bold rounded-lg hover:bg-blue-700 hover:text-white '><a href="/#services">Services</a></li>
                            <li className='p-2 mx-2 my-4 text-blue-700 text-md font-sans font-bold rounded-lg hover:bg-blue-700 hover:text-white '><a href="/#internships" >Internships</a></li>
                            <li className='p-2 mx-2 my-4 text-blue-700 text-md font-sans font-bold rounded-lg hover:bg-blue-700 hover:text-white '><a href="/#contact">Contact </a></li>
                            <li className='p-2 mx-2 my-4 text-blue-700 text-md font-sans font-bold rounded-lg hover:bg-blue-700 hover:text-white '><a href="/#faqs">FAQ's</a></li>
                            <li className='p-2 mx-2 my-4 text-blue-700 text-md font-sans font-bold rounded-lg hover:bg-blue-700 hover:text-white '><a href="/guidencepage#guidence">Guidence</a></li>
                        </ul>
                    )}
                </nav>
            </div>
        </div>
    )
}
