import React, { useRef, useEffect } from 'react'
import emailjs from '@emailjs/browser';
import image from "../Photos/Contact.jpg"

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import 'aos/dist/aos.css'; 
import AOS from 'aos';

export default function Contact() {

    useEffect(() => {
        AOS.init();
    }, []);
    
    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_bushuaa', 'template_l78a8zg', form.current, 'gUArzJhSurZMoEP4m')
            .then((result) => {
                form.current.reset();
            }, (error) => {
                console.log(error.text);
            });
            alert('Thank you for reaching out! We will get back to you soon\n');
    };

    return (
        <div data-aos="zoom-in" data-aos-delay="200" data-aos-duration="1000" id="contact" className='py-20'>
            <h1 className='text-4xl lg:text-5xl md:text-5xl sm:text-4xl font-bold text-center pb-12' >Contact Us</h1>
            <div className="flex items-center justify-center bg-white px-3">
                <div className="container mx-auto  ">
                    <div className="md:hidden">
                        <img
                            src={image}
                            alt="About Us Image"
                            className="rounded-lg shadow-md mx-auto mb-6 hover:scale-50 "
                        />
                    </div>
                    <div className='lg:flex' >
                        <div className="lg:w-1/2 mb-6 hidden md:block m-auto ">
                            <img
                                src={image}
                                alt="About Us Image"
                                className="rounded-lg "
                            />
                        </div>

                        <div className="lg:w-1/2 text-md my-auto px-5">
                            <form ref={form} onSubmit={sendEmail} >
                                <div className="mb-4 flex ">
                                    <input type="text" id="fname" name="fname" placeholder='First Name' className="w-full px-3 py-2 mx-1 my-1 border shadow-lg border-gray-300 rounded-md focus:outline-none focus:border-blue-500" required />
                                    <input type="text" id="lname" name="lname" placeholder='Last Name' className="w-full px-3 mx-1 py-2 my-1 border shadow-lg border-gray-300 rounded-md focus:outline-none focus:border-blue-500" required />
                                </div>
                                <div className="mb-4">
                                    <input type="email" id="email" name="email" placeholder='Email' className="w-full px-3 py-2 my-1 border shadow-lg border-gray-300 rounded-md focus:outline-none focus:border-blue-500" required />
                                </div>
                                <div className="mb-4">
                                    <input type="text" id="subject" name="subject" placeholder='Subject' className="w-full px-3 py-2 my-1 border shadow-lg border-gray-300 rounded-md focus:outline-none focus:border-blue-500" required />
                                </div>
                                <div className="mb-4">
                                    <textarea id="message" name="message" placeholder='Message' rows="3" className="w-full px-3 py-2 my-1 border shadow-lg border-gray-300 rounded-md focus:outline-none focus:border-blue-500" required ></textarea>
                                </div>
                                <div className='flex items-center justify-center'>
                                    <button type="submit" className="rounded-full text-lg text-center px-8 py-1 bg-blue-600 text-white hover:bg-blue-900 m-1">Send Message</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}


// College : 'service_md63xlb'  'template_asd1ha8'  'Amcak7Tpn5maKifuh'
// Personal : 'service_eif8pzh' 'template_y3vdh7x' 'vLC2S4hStmLBKRmE0'
// Edu Intern : service_bushuaa  template_l78a8zg  gUArzJhSurZMoEP4m