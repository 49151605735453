import React from "react";

const Guidance = () => {
  return (
    <div className="bg-gray-100 py-24 pb-6">
      <div id="guidance-container">
        <div className="bg-grey-100 min-h-screen py-8 px-2" id="Guidance">
          <div className="md:max-w-6xl w-full mx-auto py-12 px-4 sm:px-6 lg:px-8 bg-white shadow-md rounded-lg">
            <div className="mb -6">
              <h1 class="text-2xl font-bold mb-4 text-transparent bg-clip-text bg-gradient-to-r from-blue-900 via-blue-500 to-blue-300 animate-gradient">
                EduIntern Internship
              </h1>
              <p class="text-gray-800 font-semibold" >
                EduIntern is a compassionate organization that provides
                internships to people with a range of experiences, regardless of
                their level of technical proficiency. Our primary objective is to
                empower these young people by offering direction and readiness to
                enable them to fulfill their life's ambitions. Our goal is to
                develop relationships and grow with a diverse group of people.
                Working with people who are willing to learn and grow into their
                best selves is something we are especially committed to.
              </p>
            </div>
            <br />
            <div class="border border-solid-red "></div> <br />
            <div class="mb-6">
              <h2 class="text-xl font-bold mb-4 text-transparent bg-clip-text bg-gradient-to-r from-blue-900 via-blue-500 to-blue-300 animate-gradient">
                Why should you apply?
              </h2>
              <p class="text-gray-800 font-semibold">
                This online internship is a great way to improve your talents and
                receive crucial experience in a professional atmosphere. You will
                be able to use cutting-edge technology, creative approaches, and
                industry best practices to real-world projects. You will have a
                portfolio of work at the end of the internship that highlights
                your accomplishments and proves your readiness to confront
                difficulties in your selected industry. This internship experience
                will be a significant addition to your CV, displaying your
                dedication to lifelong learning and professional development.
              </p>
            </div>
            <br />
            <div class="border border-solid-red "></div> <br />
            <div class="mb-6">
              <h2 class="text-xl font-bold mb-4 text-transparent bg-clip-text bg-gradient-to-r from-blue-900 via-blue-500 to-blue-300 animate-gradient">
                Domains that are available
              </h2>
              <ul class="list-disc pl-5 space-y-1 text-gray-800 font-semibold">
                <li>Web Development (Basic)</li>
                <li>Full Stack Development</li>
                <li>App Development</li>
                <li>C/C++ Programming</li>
                <li>Python Programming</li>
                <li>Java Programming</li>
                <li>UI/UX Designing</li>
                <li>Machine Learning</li>
                <li>Artificial Intelligence</li>
              </ul>
            </div>
            <br />
            <div class="border border-solid-red "></div> <br />
            <div class="mb-6">
              <h2 class="text-xl font-bold mb-4 text-transparent bg-clip-text bg-gradient-to-r from-blue-900 via-blue-500 to-blue-300 animate-gradient">
                How to Apply
              </h2>
              <ol class="list-decimal pl-5 space-y-1 text-gray-800 font-semibold">
                <li>
                  Navigate to the internship area on the official website
                  indicated below.
                </li>
                <li>
                  Select the field that best fits your interests, abilities, and
                  professional objectives.
                </li>
                <li>
                  Provide correct and comprehensive information while completing
                  the Google form. Please take your time to respond in-depth,
                  emphasizing your experience and abilities relevant to the
                  internship.
                </li>
                <li>
                  Check your form to make sure the information is accurate and all
                  the required fields are filled in.
                </li>
                <li>Click the "Submit" button to send in the Google form.</li>
                <li>
                  Once your application has been filed, monitor your email or the
                  website for any messages or updates on the progress of your
                  application.
                </li>
              </ol>
            </div>
            <br />
            <div class="border border-solid-red "></div> <br />
            <div class="mb-6">
              <h2 class="text-xl font-bold mb-4 text-transparent bg-clip-text bg-gradient-to-r from-blue-900 via-blue-500 to-blue-300 animate-gradient">
                Criteria for Eligibility
              </h2>
              <ul class="list-disc pl-5 space-y-1 text-gray-800 font-semibold">
                <li>
                  Please be aware that internships are limited and will be awarded
                  on the basis of merit and quality.
                </li>
                <li>
                  Interns must possess a solid grasp of the field in which they
                  intend to enroll as well as be well-versed in their own field.
                </li>
                <li>
                  Throughout the internship, interns are expected to assume full
                  responsibility for the duties and work that are allocated to
                  them.
                </li>
                <li>
                  To guarantee a successful internship, interns have to stick to
                  all guidelines and instructions supplied by the firm.
                </li>
              </ul>
            </div>
            <br />
            <div class="border border-solid-red "></div> <br />
            <div class="mb-6">
              <h2 class="text-xl font-bold mb-4 text-transparent bg-clip-text bg-gradient-to-r from-blue-900 via-blue-500 to-blue-300 animate-gradient">
                How to successfully complete an Internship Program
              </h2>
              <ol class="list-decimal pl-5 space-y-1 text-gray-800 font-semibold">
                <li>
                  When the internship offer arrives, upload it to your LinkedIn
                  page along with a suitable hashtag, such as #eduintern
                </li>
                <li>
                  Put "Intern at the EduIntern" in the title of your LinkedIn
                  profile and list it as your most recent job.
                </li>
                <li>
                  Become a member of the official Telegram group and follow
                  EduIntern on Instagram and LinkedIn.
                </li>
                <li>
                  The specifics of your tasks will be sent to you shortly before
                  your internship begins.
                  <ul class="list-disc pl-5 space-y-1 text-gray-800 font-semibold">
                    <li>
                      You will be given several jobs to accomplish; work through
                      each one one at a time.
                    </li>
                    <li>
                      Make a brief video of such task and share it on LinkedIn
                      weekly with appropriate hashtag and also tag us.
                    </li>
                  </ul>
                </li>
                <li>
                  Make another repository on GitHub for EduIntern and submit all
                  of the tasks.
                </li>
                <li>
                  Examine at least three projects that your other interns have put
                  on LinkedIn to take part in peer evaluation.
                </li>
                <li>
                  We will email you a task submission form during the final week
                  of the internship, when you will need to turn in all of the
                  assignments along with the links to your GitHub and LinkedIn
                  video posts.
                </li>
              </ol>
            </div>
            <br />
            <div class="border border-solid-red "></div> <br />
            <div class="mt-8">
              <h2 class="text-xl font-bold mb-4 text-transparent bg-clip-text bg-gradient-to-r from-blue-900 via-blue-500 to-blue-300 animate-gradient">
                The involvement
              </h2>
              <p class="text-gray-800 font-semibold">
                Although there are no fees connected with organizing this event,
                there are certain charges related to preparing offer letters,
                internship certificates, and reference letters. Although there is
                no cost to participate, we would need a minimum donation of 49 Rs
                to assist subsidize these costs. Any extra money is appreciated
                and will help the people who are striving to make this internship
                a success for you. Your donation, in any amount, will have a
                significant impact and help guarantee that these programs will
                remain available.
              </p>
              <div class=" text-center">
                <br />
                <div class="border border-solid-red "></div>
                <button
                  className="rounded-full text-lg text-center px-8 py-1 bg-blue-600 text-white hover:bg-blue-900 m-3 "

                >
                  <a href="https://bit.ly/48RIvJa" rel="noreferrer" target="_blank">Apply Now</a>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Guidance;
