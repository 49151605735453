import React from 'react';
import image from "./Photos/Background.jpg";
import Home from './Home/Home';
import About from './About/About';
import Services from './Services/Services';
import Internships from './Internships/Internships';
import Contact from './Contact/Contact';
import FAQ from "./FAQ/Faq";

export default function Code() {
  return (
    <div>
      <div className="bg-cover bg-center h-screen max-h-full max-w-full" style={{ backgroundImage: `url(${image})` }}>
        <Home />
      </div>
      <About />
      <Services />
      <Internships />
      <Contact />
      {/* <Terms /> */}
      <FAQ />
    </div>
  )
}
