import React from "react";
import "./Terms.css";
const Terms = () => {
  return (
    <div className="bg-gray-100 py-16 pb-0" >
      <div className="mx-auto p-4">
        <div className="bg-grey-100 min-h-screen py-8 px-2">
          <div className="col-span-8">
            <section id="Terms&Conditions" className="py-8 lg:py-4">
              <div className="md:max-w-6xl w-full mx-auto py-4 px-4 sm:px-6 lg:px-8 bg-white shadow-md rounded-lg">
                <div className="card-body p-10 lg:p-10">
                  <h1 className="text-3xl font-bold mt-4 mb-4 text-transparent bg-clip-text bg-gradient-to-r from-blue-900 via-blue-500 to-blue-300 animate-gradient">
                    1. Terms and Conditions
                  </h1>
                  <ul className="list-disc lg:ml-6 ml-4 mb-4 lg:mb-6">
                    <li>
                      <p className="mb-2">
                        By submitting an application for an EduIntern internship,
                        participants consent to receive marketing and
                        communication emails about the internship opportunity as
                        well as additional relevant details.
                      </p>
                    </li>
                    <li>
                      <p className="mb-2">
                        When EduIntern sends out a letter offering an internship,
                        it's regarded as an acceptance of the internship
                        conditions. Upon receiving this offer, it is presumed that
                        the receiver has consented to take part in the program.
                      </p>
                    </li>
                    <li>
                      <p className="mb-2">
                        Professional conduct is required of interns who join the
                        EduIntern WhatsApp group. It is completely forbidden to
                        message other interns personally without their permission.
                        Legal prosecution and termination from the internship
                        program are possible outcomes of violations.
                      </p>
                    </li>
                    <li>
                      <p className="mb-2">
                        Certificate and Letter of Reference (LOR): Only once the
                        internship is successfully completed are certificates and
                        LORs granted. After the first update, any further changes
                        to the intern's information won't be accepted.
                      </p>
                    </li>
                  </ul>
                  <p className="mb-2">
                    Note that EduIntern Internship retains the right to modify
                    these terms and conditions at any time. It is the intern's
                    responsibility to remain up up-to-date on any changes or
                    amendments to these conditions. To be eligible for the
                    internship program, you must abide by these rules.
                  </p>
                </div>
              </div>
            </section>

            <section id="PrivacyPolicy" className="py-8 lg:py-4">
              <div className="md:max-w-6xl w-full mx-auto py-4 px-4 sm:px-6 lg:px-8 bg-white shadow-md rounded-lg">
                <div className="card-body p-10 lg:p-10">
                  <h1 className="text-3xl font-bold mt-4 mb-4 text-transparent bg-clip-text bg-gradient-to-r from-blue-900 via-blue-500 to-blue-300 animate-gradient">
                    2. Privacy Policy
                  </h1>
                  <p className="mb-2">
                    We at EduIntern Internship are dedicated to preserving the
                    confidentiality and security of the data pertaining to our
                    interns. This Privacy Policy defines how we collect, use,
                    reveal, and secure personal information while you engage in
                    our internship program.
                  </p>

                  <h3 className="text-xl font-semibold mt-8 mb-4">
                    Information Collection:
                  </h3>
                  <ul className="list-disc  lg:ml-6 ml-4 mb-4 lg:mb-6">
                    <li>
                      <p className="mb-2">
                        1. Personal identity Data: Name, email address, postal
                        address, phone number, and educational background are just
                        a few examples of the personal identity data that we could
                        gather.
                      </p>
                    </li>
                    <li>
                      <p className="mb-2">
                        2. Usage & Log Data: Information on how you utilize our
                        products and services, includes log files, browsing
                        habits.
                      </p>
                    </li>
                  </ul>

                  <h3 className="text-xl font-semibold mt-8 mb-4">
                    Use of Information:
                  </h3>
                  <p className="mb-2">
                    We utilize the data we gather for the following purposes:
                  </p>
                  <ul className="list-disc  lg:ml-6 ml-4 mb-4 lg:mb-6">
                    <li>
                      <p className="mb-2">
                        1. Management and administration of the internship
                        program, including communications and logistics.
                      </p>
                    </li>
                    <li>
                      <p className="mb-2">
                        2. Service Enhancement: To improve our program offerings
                        and tailor your experience.
                      </p>
                    </li>
                    <li>
                      <p className="mb-2">
                        3. Compliance and Safety: To meet legal requirements while
                        also ensuring the safety of our participants.
                      </p>
                    </li>
                  </ul>

                  <h2 className="text-xl font-semibold mt-8 mb-4">
                    Information Exchange:
                  </h2>
                  <p className="mb-2">
                    We don’t exchange, buy, or rent your private information to
                    other parties. We may provide our business partners,
                    dependable affiliates, and advertisers with general aggregated
                    demographic data that isn't connected to any personally
                    identifiable information about visitors and users for the uses
                    mentioned above.
                  </p>

                  <h2 className="text-xl font-semibold mt-8 mb-4">
                    Information Security:
                  </h2>
                  <p className="mb-2">
                    We use suitable procedures for gathering, storing, and
                    handling data as well as security measures to guard against
                    unauthorized access, modification, disclosure, or elimination
                    of your personal data and other data kept on our website.
                  </p>

                  <h2 className="text-xl font-semibold mt-8 mb-4">
                    You Have Rights:
                  </h2>
                  <p className="mb-2">
                    It is within your rights to view, update, or remove your
                    personal data. If you would want to use these rights, please
                    get in touch with us using the information below.
                  </p>

                  <h2 className="text-xl font-semibold mt-8 mb-4">
                    Modifications to This Privacy Notice:
                  </h2>
                  <p className="mb-2">
                    At any time, EduIntern Internship retains the right to amend
                    this privacy statement. The revised date at the bottom of this
                    web page will be updated once we have done so. In order to
                    keep updated about how we are safeguarding the personal
                    information we collect, we recommend users to regularly visit
                    this page for any updates.
                  </p>

                  <h2 className="text-xl font-semibold mt-8 mb-4">
                    Acceptance of the Following Conditions:
                  </h2>
                  <p className="mb-2">
                    You indicate your approval of this policy by applying for the
                    CodeClause Internship. Kindly refrain from enrolling in our
                    program if you are opposed to this policy.
                  </p>

                  <h2 className="text-xl font-semibold mt-8 mb-4">
                    Getting in touch with us:
                  </h2>
                  <p className="mb-2">
                    If you have any inquiries concerning this Privacy Policy,
                    EduIntern Internship's procedures, or your interactions with
                    this program, please email us at contact@eduintern.com
                  </p>

                  <p className="mb-2">
                    This document was last updated on 10/01/2024.
                  </p>
                </div>
              </div>
            </section>

            <section id="RefundPolicy" className="py-8 lg:py-4">
              <div className="md:max-w-6xl w-full mx-auto py-4 px-4 sm:px-6 lg:px-8 bg-white shadow-md rounded-lg">
                <div className="card-body p-10 lg:p-10">
                  <h1 className="text-3xl font-bold mt-4 mb-4 text-transparent bg-clip-text bg-gradient-to-r from-blue-900 via-blue-500 to-blue-300 animate-gradient">
                    3. Refund Policy
                  </h1>
                  <ul className="list-disc lg:ml-6 ml-4 mb-4 lg:mb-6">
                    <li>
                      <p className="mb-2">
                        Document and Maintenance cost: EduIntern Internship must
                        receive payment for a document and maintenance cost from
                        each participant. This charge pays for the expenses
                        related to running and maintaining the internship program.
                      </p>
                    </li>
                    <li>
                      <p className="mb-2">
                        Not refundable After Application Verification: The money
                        paid is not refundable under any circumstances after your
                        submission has been confirmed as successful or after it
                        has been sent.
                      </p>
                    </li>
                    <li>
                      <p className="mb-2">
                        Not Give Back Post-Receipt of Certificates: No claims for
                        refunds will be taken into consideration or handled once
                        participants get their certificates and any other relevant
                        documentation from EduIntern Internship.
                      </p>
                    </li>
                    <li>
                      <p className="mb-2">
                        Refunds for Duplicated Payments: Only in the event of
                        duplicate payments will refunds be given. In these kinds
                        of situations, the participant must present legitimate
                        documentation of the overpayment. The money that was
                        overpaid will be reimbursed after verification.
                      </p>
                    </li>
                    <li>
                      <p className="mb-2">
                        Compensation for Unsubmitted Applications: In the event
                        that payment is received but the application for the
                        internship is not completed, reimbursements may be
                        granted. In these situations, participants are required to
                        furnish proof of their non-submission in order for the
                        refund to be completed.
                      </p>
                    </li>
                  </ul>
                  <p className="mb-2">
                    Kindly Note: Refund claims have to be submitted properly using
                    the methods that EduIntern Internship offers. After the refund
                    request has been approved, refunds, if applicable, will be
                    executed within a predetermined number of days. The content of
                    this policy is subject to modification at any time, at the
                    sole discretion of EduIntern Internship. Any updates will take
                    effect immediately following publication on our website.
                  </p>
                </div>
              </div>
            </section>

            <section id="Copyrights" className="py-8 lg:py-4">
              <div className="md:max-w-6xl w-full mx-auto py-4 px-4 sm:px-6 lg:px-8 bg-white shadow-md rounded-lg">
                <div className="card-body p-10 lg:p-10">
                  <h1 className="text-3xl font-bold mt-4 mb-4 text-transparent bg-clip-text bg-gradient-to-r from-blue-900 via-blue-500 to-blue-300 animate-gradient">4. Copyrights</h1>
                  <p className="mb-2">
                    Copyright © 2024 EduIntern. All Rights Reserved.
                  </p>
                  <p className="mb-2">
                    International copyright and intellectual property laws protect
                    all content and materials supplied by EduIntern Internship,
                    including but not limited to text, graphics, logos, icons,
                    images, audio clips, online downloads, data compilations, and
                    software. The property of EduIntern or its content providers
                    includes all of these items.
                  </p>
                  <p className="mb-2">
                    Without the explicit written permission of EduIntern, no
                    portion of this information may be replicated, copied, sold,
                    resold, visited, or used in any other way for any commercial
                    purpose. Without our prior written authorization, you are not
                    permitted to use any framing methods to enclose any trademark,
                    logo, or other proprietary material (including images, text,
                    page layout, or forms) of EduIntern. Without EduIntern's prior
                    written approval, you are not permitted to use any meta tags
                    or other "hidden text" that makes use of EduIntern's name or
                    trademarks. The authorization or license provided by EduIntern
                    ends with any unlawful usage.
                  </p>
                  <p className="mb-2">
                    Notice of Copyright Regarding EduIntern Internship All Rights
                    Reserved. Copyright © 2024 EduIntern Internship. Everybody The
                    information and resources made available by EduIntern
                    Internship, such as text, graphics, logos, icons, images,
                    audio clips, digital downloads, data collections, and
                    programs, are the trademarks of EduIntern or its content
                    providers and are shielded from infringement by international
                    copyright and intellectual property laws. Nothing on this page
                    may be used for profit without the full written permission of
                    EduIntern. No portion of this content may be copied,
                    duplicated, traded in, sold again, visited, or used in any
                    other way. Without our prior written authorization, you are
                    not permitted to use any framing methods to include any
                    trademark, logo, or other proprietary material of EduIntern.
                    Without EduIntern's prior written approval, you are not
                    permitted to use any meta tags or other "hidden text" that
                    makes use of EduIntern's name or trademarks. The authorization
                    or license provided by EduIntern ends with any unlawful usage.
                    EduIntern maintains the right, at any time and without prior
                    notice, to modify any and all of the material on this website.
                  </p>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Terms;
